import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import "fontsource-shrikhand";
import resume from "../../static/resume-slay.pdf";
import { Modal } from "../components/Modal";
import altogif from "../images/altoscreencap.gif";
import chesepng from "../images/chesescreenshot.jpg";
import chesemp4 from '../images/chese-demo.mp4';
import lahvahgif from "../images/lahvahscreencap.gif";
// import chesepng from '../images/watercool.jpg';
// import lahvahpng from '../images/watercool.jpg';

/*
Want this box to auto balance in the page until it shifts
to mobile layout
*/
const Box = styled.div`
  margin: 5%;
  @media (max-width: 810px) {
    margin: 10% 5% 10% 5%;
  }
  @media (max-width: 450px) {
    margin 8% 5% 8% 5%;
  }
`;

const Foreground = styled.div`
  background-color: var(--color-background);
  margin: auto;
  padding: 3% 5%;
`;

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NameHeader = styled.h1`
  font-family: "Times New Roman";
  font-size: 82px;
  margin-bottom: 0px;
  margin-top: 0px;
  @media (max-width: 810px) {
  }
  @media (max-width: 450px) {
    font-size: 40px;
  }
`;

const HeaderTwo = styled.h2`
  font-family: "Times New Roman";
  font-size: 48px;
  margin-top: 0px;
  margin-bottom: 100px;
  @media (max-width: 810px) {
    margin-bottom: 115px;
  }
  @media (max-width: 450px) {
    font-size: 32px;
    margin-bottom: 30px;
  }
`;

const ResumeDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 450px) {
    display: none;
  }
`;

const ResumeDivMobile = styled(ResumeDiv)`
  display: none;
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    // margin-right: auto;
    // margin-left: auto;
    // margin-top: 0px;
    margin-bottom: 30px;
  }
`;

const ResumeLinks = styled.a`
  font-family: "Times New Roman";
  font-size: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
  @media (max-width: 450px) {
    margin: 0px auto 10px auto;
    font-size: 24px;
  }
`;

const Bio = styled.div`
  color: var(--color-text);
  font-size: 36px;
  margin-right: 60%;
  @media (max-width: 810px) {
    margin-right: 40%;
    margin-bottom: 125px;
  }
  @media (max-width: 450px) {
    font-size: 18px;
    margin-right: auto;
    margin-bottom: 0px;
  }
`;

const ProjectHeader = styled.h2`
  font-family: "Times New Roman";
  font-size: 48px;
  margin-top: 75px;
  margin-bottom: 50px;
  font-weight: bold;
  @media (max-width: 450px) {
    margin-bottom: 30px;
    margin-top: 50px;
    font-size: 32px;
  }
`;

const ProjectBox = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: space-evenly;
  align-items: baseline;
  @media (max-width: 810px) {
    margin-bottom: 50px;
    align-items: center;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    margin-bottom: 50px;
    align-items: center;
  }
`;

// padding on the project is for the hover state color change fluidity
const Project = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  transition: 0.5s;
  :hover {
    background: var(--color-text);
  }
`;

const ProjectTitle = styled.text`
  margin: 10px auto 0px auto;
  font-size: 48px;
  transition: 0.5s;
  ${Project}:hover & {
    color: var(--color-background);
  }
  @media (max-width: 810px) {
    font-size: 32px;
  }
  @media (max-width: 450px) {
    font-size: 32px;
  }
`;

const ProjectSubText = styled.text`
  transition: 0.5s;
  ${Project}:hover & {
    color: var(--color-background);
  }
`;

/*
PROJECT IN DEPTH (PIDs)
*/

const PIDWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3%;
  overflow-y: scroll;
`;

const PIDProjectTitle = styled.a`
  margin: 10px auto 0px auto;
  font-size: 72px;
  transition: 0.5s;
  position: relative;
  @media (max-width: 810px) {
    font-size: 48px;
  }
  @media (max-width: 450px) {
    font-size: 32px;
  }
`;

const PIDBio = styled.p`
  color: var(--color-text);
  font-size: 36px;
  margin-right: 60%;
  @media (max-width: 810px) {
    margin-right: 0%;
    // margin-bottom: 125px;
  }
  @media (max-width: 450px) {
    font-size: 18px;
    margin-right: auto;
    margin-bottom: 0px;
  }
`;

const PIDTechTitle = styled.h2`
  color: var(--color-text);
  font-size: 24px;
  font-weight: bold;
`;

const PIDTech = styled.ul`
  color: var(--color-text);
  @media (max-width: 810px) {
    margin-right: 40%;
    margin-bottom: 125px;
  }
  @media (max-width: 450px) {
    font-size: 18px;
    margin-right: auto;
    margin-bottom: 0px;
  }
`;

const PIDTechLi = styled.li`
  font-size: 24px;
`;

const PIDGif = styled.img`
  position: absolute;
  right: 5%;
  top: 30%;
  @media (max-width: 810px) {
    position: relative;
    top: 0;
    right: 0;
    width: 90%;
    margin: 10px auto 0 auto;
  }
  @media (max-width: 450px) {
    position: relative;
    top: 0;
    right: 0;
    width: 90%;
    margin: 10px auto 0 auto;
  }
`;

const PIDVid = styled.video`
  position: absolute;
  right: 5%;
  top: 30%;
  @media (max-width: 810px) {
    position: relative;
    top: 0;
    right: 0;
    width: 90%;
    margin: 10px auto 0 auto;
  }
  @media (max-width: 450px) {
    position: relative;
    top: 0;
    right: 0;
    width: 90%;
    margin: 10px auto 0 auto;
  }
`;

// markup
const IndexPage = () => {
  const [pidAlto, setPidAlto] = useState(false);
  const [pidLahvah, setPidLahvah] = useState(false);
  const [pidChese, setPidChese] = useState(false);

  const AltoPID = (
    <>
      <Modal showModal={pidAlto} setShowModal={setPidAlto}>
        <PIDWrapper>
          <PIDProjectTitle
            style={{ fontFamily: "Shrikhand" }}
            href="https://alto.earth"
            target="_blank"
          >
            alto
            <div style={{ position: "absolute", left: "105%", top: "0px" }}>
              <LinkIcon />
            </div>
          </PIDProjectTitle>

          <PIDBio>
            My first website. Built to teach myself React and help beginner
            plant lovers easily find information.
          </PIDBio>
          <PIDGif src={altogif} width="50%" />
          <PIDTechTitle>Tech Stack</PIDTechTitle>
          <PIDTech>
            <PIDTechLi>React</PIDTechLi>
            <PIDTechLi>GatsbyJS</PIDTechLi>
            <PIDTechLi>GraphQL</PIDTechLi>
            <PIDTechLi>MongoDB</PIDTechLi>
            <PIDTechLi>Express (Used before GraphQL)</PIDTechLi>
            <PIDTechLi>Styled-Components</PIDTechLi>
            <PIDTechLi>Material-UI</PIDTechLi>
            <PIDTechLi>React Helmet</PIDTechLi>
          </PIDTech>
        </PIDWrapper>
      </Modal>
    </>
  );
  const ChesePID = (
    <>
      <Modal showModal={pidChese} setShowModal={setPidChese}>
        <PIDWrapper>
          <PIDProjectTitle
            style={{
              fontFamily: "Basier Circle",
              textUnderlineOffset: "0.1em",
            }}
            href="https://chese.onrender.com"
            target="_blank"
          >
            chese
            <div style={{ position: "absolute", left: "105%", top: "0px" }}>
              <LinkIcon />
            </div>
          </PIDProjectTitle>

          <PIDBio>
            An NFT marketplace live on the Goerli testnet. This is my most complex project I have built yet. I started this to
            force myself to learn the intricacies of front and back end development in the growing Web3 sector.
            I'm using some of my favorite new tools: Typescript, and Tailwind. 
            My primary targets for learning was Solidity, Ethers.js, and Hardhat.
            Incorporated 3 API sources: Alchemy, Infura, and Pinata.
          </PIDBio>
          <PIDVid src={chesemp4} controls width="50%" />
          {/* buthol */}
          <PIDTechTitle>Tech Stack</PIDTechTitle>
          <PIDTech>
            <PIDTechLi>React</PIDTechLi>
            <PIDTechLi>Typescript</PIDTechLi>
            <PIDTechLi>Tailwind</PIDTechLi>
            <PIDTechLi>Ethers.js</PIDTechLi>
            <PIDTechLi>Hardhat</PIDTechLi>
            <PIDTechLi>Solidity</PIDTechLi>
            <PIDTechLi>Alchemy, Infura, and Pinata APIs</PIDTechLi>
          </PIDTech>
        </PIDWrapper>
      </Modal>
    </>
  );
  const LahvahPID = (
    <>
      <Modal showModal={pidLahvah} setShowModal={setPidLahvah}>
        <PIDWrapper>
          <PIDProjectTitle
            style={{ fontFamily: "Juana Black", fontWeight: "normal" }}
            href="https://lahvah.onrender.com"
            target="_blank"
          >
            lahvah
            <div style={{ position: "absolute", left: "105%", top: "0px" }}>
              <LinkIcon />
            </div>
          </PIDProjectTitle>

          <PIDBio>
            I wanted to leave my comfort zone of familiar tech and see how other
            popular frameworks/libraries function. I took an old UI mockup and
            used it to learn TypeScript, TailWind, and NextJS. Going forward I
            see myself often using TypeScript and Tailwind.
          </PIDBio>
          <PIDGif src={lahvahgif} width="50%" />
          <PIDTechTitle>Tech Stack</PIDTechTitle>
          <PIDTech>
            <PIDTechLi>React</PIDTechLi>
            <PIDTechLi>TypeScript</PIDTechLi>
            <PIDTechLi>NextJS</PIDTechLi>
            <PIDTechLi>Tailwind</PIDTechLi>
          </PIDTech>
        </PIDWrapper>
      </Modal>
    </>
  );

  return (
    <main>
      <title>Madison Fries</title>
      <body>
        <Box>
          <Foreground>
            <TopLine>
              <NameHeader>Hi! I'm Maddy.</NameHeader>
              <ResumeDiv>
                <ResumeLinks
                  href={resume}
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  resume
                </ResumeLinks>
                <text style={{ fontSize: "32px" }}>//</text>
                <ResumeLinks
                  href="https://github.com/michecode"
                  target="_blank"
                >
                  github
                </ResumeLinks>
                <text style={{ fontSize: "32px" }}>//</text>
                <ResumeLinks
                  href="mailto:madisonfries@protonmail.com"
                  target="_blank"
                >
                  email
                </ResumeLinks>
              </ResumeDiv>
            </TopLine>

            <HeaderTwo>
              I'm a Front End Engineer 
              @ Charged Particles
            </HeaderTwo>
            <ResumeDivMobile>
              <ResumeLinks
                href={resume}
                without
                rel="noopener noreferrer"
                target="_blank"
              >
                resume
              </ResumeLinks>

              <ResumeLinks href="https://github.com/michecode" target="_blank">
                github
              </ResumeLinks>

              <ResumeLinks
                href="mailto:madisonfries@protonmail.com"
                target="_blank"
              >
                email
              </ResumeLinks>
            </ResumeDivMobile>
            <Bio>
              I&apos;m a 21 year old based in Los Angeles. I love creating beautiful
              products that are easy to use across devices.
            </Bio>
            {console.log("rendering projects now")}
            <ProjectHeader>My Projects:</ProjectHeader>
            <ProjectBox>
              <Project onClick={() => setPidAlto(!pidAlto)}>
                <ProjectTitle style={{ fontFamily: "shrikhand" }}>
                  alto
                </ProjectTitle>
                <ProjectSubText>[Live Website]</ProjectSubText>
              </Project>
              {AltoPID}
              <Project onClick={() => setPidChese(!pidChese)}>
                <ProjectTitle style={{ fontFamily: "Basier Circle" }}>
                  chese
                </ProjectTitle>
                <ProjectSubText>[Live Website]</ProjectSubText>
              </Project>
              {ChesePID}
              <Project onClick={() => setPidLahvah(!pidLahvah)}>
                <ProjectTitle style={{ fontFamily: "Juana Black" }}>
                  lahvah
                </ProjectTitle>
                <ProjectSubText>[Live Website]</ProjectSubText>
              </Project>
              {LahvahPID}
            </ProjectBox>
          </Foreground>
        </Box>
      </body>
    </main>
  );
};

function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="24"
      width="24"
      fill="var(--color-text)"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M14 5C13.4477 5 13 4.55228 13 4C13 3.44772 13.4477 3 14 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4L21 10C21 10.5523 20.5523 11 20 11C19.4477 11 19 10.5523 19 10L19 6.41422L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L17.5858 5H14ZM3 7C3 5.89543 3.89543 5 5 5H10C10.5523 5 11 5.44772 11 6C11 6.55228 10.5523 7 10 7H5V19H17V14C17 13.4477 17.4477 13 18 13C18.5523 13 19 13.4477 19 14V19C19 20.1046 18.1046 21 17 21H5C3.89543 21 3 20.1046 3 19V7Z"
      ></path>
    </svg>
  );
}

export default IndexPage;
